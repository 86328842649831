html {
  font-size: 62.5%; /* 16px * 62.5 = 10px = 1rem */
  box-sizing: border-box;
}
body {
  font: 1.6rem Helvetica;
  height: 100vh;
  margin: 0;
}
button {
  font-size: 1.6rem;
}
.addButton {
  font-size: 1.6rem;
  width: 100%;
}
.full-width {
  width: 100%;
}
#root {
  height: 100%;
}
.grid-container {
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer'
    ;
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr 5rem;
  height: 100%;
}
.header {
  left: 0;
  top: 0;
  position: fixed;
  grid-area: header;
  display: flex;
  width: 100%;
  /* grid-area: header;
  background-color: #80A6EA;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem; */

}

.brand a {
  color: #ffffff;
  font-size: 3rem;
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 10;
}
.header-links a {
  color: #ffffff;
  text-decoration: none;
  padding: 1rem;
}
.header-links a:hover {
  color: #ff8000;
}
@media screen and (max-width: 800px) {
  .header {
    align-self: auto;
    padding: -1.0rem;
  }
  .header-search {
    margin-left: 12px;
    width: 50%;
  }
  .brand{
    margin-left: 5px;
  }
  .brand a {
    font-size: medium;
  }
  .shopping-icon{
    width: 50px;
    height: 25px;
  }
  .header-links{
    padding: -1.0rem;
  }
  .header-links a{
    font-size: small;
  }
}
.main {
  grid-area: main;
  background-color: #ffffff;
  padding-bottom: 16px;

}
@media screen and (max-width: 800px) {
    .main {
      margin-top: 30px;
    }
}
.footer {
  grid-area: footer;
  background-color: #80A6EA;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 40px;
  width: 100%;
}
a {
  text-decoration: none;
}
a:hover {
  color: #ff8000;
}
/* Home Screen */
.products {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.products li {
  list-style-type: none;
  padding: 0;
  flex: 0 1 34rem;
  margin: 1rem;
  height: 35rem;
  /* border-bottom: 0.1rem #c0c0c0 solid; */
}
.product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}
.product-name {
  font-size: 2rem;
  display: block;
  font-weight: bold;
}
.product-brand {
  font-size: 1.2rem;
  color: #808080;
}
.product-price {
  font-size: 2rem;
  font-weight: bold;
}
.product-image {
  max-width: 30rem;
  max-height: 30rem;
  opacity: 1;
  border-radius: 8px;
}
.product-rating {
  margin-bottom: 1rem;
}
/* Reviews */
ul.review {
  list-style-type: none;
  padding: 0;
}
.review li {
  margin: 1rem;
  margin-bottom: 2rem;
}
.review li > div {
  margin: 0.5rem;
}

/* Rating */
.rating span {
  color: #ffb901;
  font-size: 1.8rem;
  margin: 0.1rem;
}
.rating span:last-child {
  color: #444444;
  font-size: 1.4rem;
}
a > .rating > span:last-child {
  color: #0000ff;
}

/* Sidebar */

.brand button {
  font-size: 3rem;
  padding: 0.5rem;
  background: none;
  border: none;
  font-size: medium;
  color:white;
  cursor: pointer;
}
.sidebar {
  position: fixed;
  transition: all 0.5s;
  transform: translateX(-30rem);
  width: 25rem;
  background-color: white;
  height: 100%;
}
.sidebar.open {
  transform: translateX(0);
}

.sidebar-close-button {
  border-radius: 50%;
  background-color: white;
  color: black;
  border: 0.1rem #000000 solid;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  font-size: 2rem;
  padding-top: 0;
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 1.5rem;
}

/* Product Details */
.details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 1rem;
}
.details-image {
  flex: 2 1 60rem;
  
}
.details-image img {
  max-width: 60rem;
  width: 100%;
  border-radius: 8px;
}
.details-info {
  flex: 1 1 30rem;
}
.details-action {
  flex: 1 1 30rem;
}

.details-info ul,
.details-action ul {
  list-style-type: none;
  padding: 0;
}
.details-info li,
.details-action li {
  margin-bottom: 1rem;
}
.back-to-result {
  padding: 1rem;
}
.details-action {
  border: 0.1rem #808080 solid;
  border-radius: 0.5rem;
  background-color: #f8f8f8;
  padding: 1rem;
}
.details-action ul li:last-child {
  display: flex;
  flex-direction: column;
}
.button {
  padding: 1rem;
  border: 0.1rem #808080 solid;
  border-radius: 0.5rem;
  cursor: pointer;
}
.button:hover {
  border: 0.1rem #404040 solid;
}
.button.primary {
  background-color: #80A6EA;
  color: 'white';
  text-decoration-color: white;
}
.button.secondary {
  background-color: #f0f0f0;
}
.text-center {
  text-align: center;
}
/* Cart */

.cart {
  display: flex;
  flex-wrap: wrap;
  margin: -0.1rem;
  align-items: flex-start;
}
.cart-list {
  flex: 3 1 60rem;
}
.cart-action {
  flex: 1 1 20rem;
  background-color: #f0f0f0;
  border-radius: 0.5rem;
  padding: 1rem;
}
.cart-action:last-child{
  margin-bottom: 40px;
}
.cart-list-container {
  padding: 0;
  list-style-type: none;
  padding: 1rem;
}
@media screen and (max-width: 800px) {
  .MuiPaper-root a {
    font-size: small;
  }
  .MuiPaper-root > .makeStyles-card-9{
    max-width: 360px;
  }
}
.cart-list-container li {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.1rem #c0c0c0 solid;
}
.cart-list-container li img {
  max-width: 10rem;
  max-height: 10rem;
}
.cart-list-container li:first-child {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.cart-image {
  flex: 1 1;
  border-radius: 8px;
}
.cart-name {
  flex: 8 4;
  justify-content: space-between;
  margin-left: 1rem;
}
.cart-price {
  flex: 1 1;
  font-size: 2.5rem;
  text-align: right;
}
.cart-price .button {
  display: block;
  margin-left: auto;
}
/* forms */

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.form-container {
  display: flex;
  flex-direction: column;
  width: 32rem;
  padding: 2rem;
  border: 0.1rem #c0c0c0 solid;
  border-radius: 0.5rem;
  list-style-type: none;
}

.form-container li {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
input {
  padding: 1rem;
  border: 0.1rem #c0c0c0 solid;
  border-radius: 0.5rem;
}

/* Products */

.product-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.content-margined {
  margin: 1rem;
}

.table {
  width: 100%;
}
th {
  text-align: left;
}
tbody > tr:nth-child(odd) {
  background-color: #f0f0f0;
}

/* Checkout Steps */

.checkout-steps {
  display: flex;
  justify-content: space-between;
  width: 40rem;
  margin: 1rem auto;
}
@media screen and (max-width: 800px) {
  .checkout-steps {
    margin-top: 45px;
  }
}
.checkout-steps > div {
  border-top: 0.3rem  #80A6EA;
  color: black;
  flex: 1 1;
  padding-top: 1rem;
}
.checkout-steps > div.active {
  border-top: 0.3rem #80A6EA;
  border-bottom: 0.4rem #80A6EA;
  color: #80A6EA;
}

/* Place Order */

.placeorder {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: space-between;
}
.placeorder-info {
  flex: 3 1 60rem;
}
.placeorder-action {
  flex: 1 1 20rem;
  border: 0.1rem #c0c0c0 solid;
  border-radius: 0.5rem;
  background-color: white;
  padding: 1rem;
}
.placeorder-info > div {
  border: 0.1rem #c0c0c0 solid;
  border-radius: 0.5rem;
  background-color: white;
  padding: 1rem;
  margin: 1rem;
}
.placeorder-info > div:first-child {
  margin-top: 0;
}
.placeorder-action > ul {
  padding: 0;
  list-style-type: none;
}
.placeorder-action > ul > li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.placeorder-action > ul > li:last-child {
  font-size: 2rem;
  font-weight: bold;
  color: #203040;
}
.placeorder-actions-payment > div {
  width: 100%;
}

/* Profile */

.profile {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
}

.profile-info {
  flex: 1 1 30rem;
}
.profile-orders {
  flex: 3 1 60rem;
}

/* admin */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  padding: 1rem;
  list-style-type: none;
  z-index: 1;
  background-color: #203040;
  margin: 0;
  margin-top: 0.4rem;
}
.dropdown:hover .dropdown-content {
  display: block;
}

/* filter */
.serachFilter{
  display: block !important;
  list-style-type: none;
  padding: 0;
  z-index:1;
  width: 200;
  margin: 2rem auto;
  max-width: 55rem;
  justify-content: space-between;
  align-items: center;
}
.serachFilter.open {
  visibility: hidden;
}
.filter {
  /* display: flex !important; */
  list-style-type: none;
  padding: 0;
  margin: 1rem auto;
  max-width: 45rem;
  justify-content: space-between;
  align-items: center;
}
.filter input,
.filter button,
.filter select {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem #c0c0c0 solid;
  font-size: 1.6rem;
}
.categories {
  padding: 0;
  list-style-type: none;
}
.categories a {
  display: flex;
  padding: 1rem;
}

.categories a:hover {
  background-color: #c0c0c0;
}

.notification {
  color: white;
  text-decoration: none;
  padding: 15px 26px;
  position: relative;
  display: inline-block;
  border-radius: 2px;
}

.notification:hover {
  background: #f08000 solid;
}
.notification .badge {
  position: absolute;
  top: 10px;
  right: 25px;
  padding: 5px 10px;
  border-radius: 50%;
  background:#c04000;
  color: #f08000 solid;
}
#form-wrapper {
  width: auto;
  height: 40px;
}

.nav-list {
  padding: 10px 25px 10px 5px;
  position: relative;
  float: left;
  border: 1px solid orange;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

#search-dropdown {
  cursor: pointer;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid orange;
}

#search-dropdown:hover {
  background-color: lightgray;
}

.current-selection {
  display: inline-block;
  font-size: 14px;
}

.in-wrap {
  overflow: hidden;
  height: 100%;
}

#search-box {
  width: 100%;
  height: 36px;
  border: 1px solid orange;
  border-left: none;
  border-right: none;
  line-height: 25px;
  font-size: 18px;
  padding-left: 100px;
}

#search-box:focus {
  outline: none;
}

.go-button {
  float: right;
  height: 100%;
  background-color: orange;
  border: 1px solid orange;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 0;
  padding: 0 15px;
}

.go-button:hover {
  background-color: rgb(255, 115, 0);
  cursor: pointer;
}
.cartSidebar {
  position: fixed;
  transition:all 550ms cubic-bezier(0.645,0.045,0.355,1.000);
  transform: translateX(-30rem);
  width: 30rem;
  scroll-margin-bottom: 100px;
  overflow-y: auto;
  overflow-x: auto;
  background-color: white;
  max-height: 70rem !important;
  height: auto !important ;
}
.cartSidebar::-webkit-scrollbar{
  width: 1rem;
  height: 100px;
  background-color: whitesmoke;    /* color of the scroll thumb */
  border-radius: 2px solid;       /* roundness of the scroll thumb */
  border: 1px solid white;
}
.cartSidebar::-webkit-scrollbar-thumb{
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #203040;
}
.cartSidebar.open {
  transform: translateY(-0);
  width: 35rem;
  right: 0;
}
@media screen and (max-width: 800px) {
  .cartSidebar.open {
    width: 100%;
  }
  .cartSidebar.open::before{
    background-color: red;
  }
}
.cartSidebar-close-button {
  border-radius: 50%;
  background-color: white;
  color: black;
  border: 0.1rem #000000 solid;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  font-size: 2rem;
  padding-top: 0;
  cursor: pointer;
  position: absolute;
  left: 1.5rem;
  top: 1.5rem;
}
.thank_you{
  position: relative;
  top: 120px;
  right: 0 ;
  bottom: 0;
  left:40% ;
  height: 40% ;
  width: 30% ;
  background-image: 'nophoto.jpg';
  background-color: white;
}
.add-button{
 box-shadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)';
 margin-left: '5px'
}
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}
@media screen and (max-width: 800px) {
  .popup-box{
    top: 45px;
  }
  .close-icon{
    top: 20px;
    width: 30px;
    height: 30px;
    margin-left: 100%;
    position: relative;
    
  }
}

/* .carousel-container {
  width: 100%;
 } */
/* 
@media screen and (max-width: 800px) {
  .itemName{
    font-size: 10;
    font-weight: bold;
  }
  .itemVaritaionName{
    font-size: 12;
  }
  .itemDescription{
    font-size: 8px;
    height: 10;
  }
  .itemPrice {
    font-size: 12px;
  }
} */